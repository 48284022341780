import React, { FC } from "react";
import { graphql, Link } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import { Layout, SEO, Scripture, H2, Iframe, Footer } from "components";
import { Frontmatter } from "types/frontmatter";
import { useCardColor } from "hooks/useCardColor";
import { MDXProvider } from "@mdx-js/react";
import dayjs from "dayjs";
import { formatDate } from "utils/formatDate";

export interface Props {
  data: {
    site: {
      siteMetadata: {
        title: string;
        author: string;
      };
    };
    mdx: {
      id: string;
      frontmatter: Frontmatter;
      body: string;
    };
  };
  pageContext: any;
}

const BlogPostTemplate: FC<Props> = ({ data, pageContext }) => {
  const post = data.mdx;
  const color = useCardColor(`${post.frontmatter.slug}`);

  return (
    <MDXProvider
      components={{
        Scripture,
        h2: H2,
        iframe: Iframe,
      }}
    >
      <Layout>
        <SEO
          title={`${post.frontmatter.title || ""} - ${dayjs(
            formatDate(post.frontmatter.date)
          ).format("YYYY. MMMM DD. HH:mm")}`}
          description={"post.excerpt"}
        />
        <div>
          <Link
            to="/"
            className="absolute lg:fixed top-0 left-0 p-4 lg:m-2 lg:p-2 hover:bg-white hover:text-green-600 shadow-lg rounded-full text-gray-50"
            style={{ mixBlendMode: "difference" }}
            title="Visszalépés"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-8 h-8"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
              />
            </svg>
          </Link>
          <div
            className={`bg-brand-${color} w-full h-64 lg:h-128 flex justify-center items-center flex-col`}
          >
            <h1 className="text-3xl leading-8 font-extrabold tracking-tight text-gray-50 sm:text-4xl sm:leading-10">
              {post.frontmatter.title}
            </h1>
            <h2 className="text-gray-50 text-xl leading-8">
              {dayjs(formatDate(post.frontmatter.date)).format(
                "YYYY. MMMM DD. HH:mm"
              )}
            </h2>
            <h3 className="text-gray-50 leading-8 mt-8">
              {[
                post.frontmatter.encourager || "",
                post.frontmatter.teacher || "",
              ]
                .filter((item) => !!item)
                .join(", ")}
            </h3>
          </div>
          <div className="bg-white m-0 p-4 lg:p-8 lg:m-16 lg:-mt-12 xl:p-8 xl:m-64 xl:-mt-16 rounded shadow">
            <p className="text-base text-center leading-6 text-teal-500 font-semibold tracking-wide uppercase mb-8 lg:mb-16">
              {post.frontmatter.date}
            </p>
            <MDXRenderer>{post.body}</MDXRenderer>
          </div>
        </div>
        <footer className="w-full px-4 py-4 lg:px-16 lg:py-16 flex justify-between">
          <Link
            to={pageContext.previous ? pageContext.previous.fields.slug : "/"}
            className=""
            aria-disabled={!pageContext.previous}
          >
            <button
              role="button"
              type="button"
              disabled={!pageContext.previous}
              className={`px-8 py-2 bg-white shadow-md rounded ${
                !pageContext.previous
                  ? "text-gray-100 cursor-not-allowed"
                  : "text-black"
              }`}
            >
              Előző
            </button>
          </Link>
          <Link
            to={pageContext.next ? pageContext.next.fields.slug : "/"}
            className=""
            aria-disabled={!pageContext.next}
          >
            <button
              role="button"
              type="button"
              disabled={!pageContext.next}
              className={`px-8 py-2 bg-white shadow-md rounded ${
                !pageContext.next
                  ? "text-gray-100 cursor-not-allowed"
                  : "text-black"
              }`}
            >
              Következő
            </button>
          </Link>
        </footer>
        <hr />
      </Layout>
      <Footer />
    </MDXProvider>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      frontmatter {
        title
        date
        slug
        encourager
        teacher
      }
      body
    }
  }
`;
